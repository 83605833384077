function bjTime(timestamp) {
  const d = new Date(timestamp)
  const month = d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
  const date = d.getFullYear() + '-' + month + '-' + d.getDate()
  const hour = d.getHours() > 9 ? d.getHours() : '0' + d.getHours()
  const minute = d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes()
  const ss = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()
  const time = date + ' ' + hour + ':' + minute + ':' + ss
  return time
}
export default bjTime
