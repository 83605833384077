<template>
  <div class="LiveAnnouncement">
    <div class="head_menu">
      <div class="left_menu">
        <div class="input">
          <el-input
            placeholder="请输入你需要搜索的公告名称"
            suffix-icon="el-icon-search"
            v-model="filterForm.name"
            @change="filterNameFun"
          >
          </el-input>
        </div>
        <div class="select">
          <span class="select_title">公告状态：</span>
          <el-select
            @change="selectFilter"
            v-model="filterForm.online_flag"
            placeholder="请选择"
          >
            <el-option
              v-for="item in liveStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="right_menu">
        <span @click="newAnnouncement" class="new_btn">新建公告</span>
      </div>
    </div>
    <div class="table">
      <el-table
        :header-cell-style="{
          background: '#f5f5f5',
        }"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="name" label="公告名称"> </el-table-column>
        <el-table-column prop="introduction" label="培训内容">
        </el-table-column>
        <el-table-column prop="link" label="培训链接">
          <template v-slot="scope">
            <span
              @click="openLink(scope.row.link)"
              style="color: #7d8bff; cursor: pointer"
              >{{ scope.row.link }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="training_time" label="培训时间">
        </el-table-column>
        <el-table-column prop="teacher_name" label="培训讲师">
        </el-table-column>
        <el-table-column prop="online_flag" label="公告状态">
          <template v-slot="scope">
            <span
              :style="{ color: scope.row.online_flag == 1 ? '#25c672' : '' }"
              >{{ scope.row.online_flag == 1 ? '已上线' : '未上线' }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template v-slot="scope">
            <el-button
              :style="{
                color: scope.row.online_flag == 1 ? '#ccc' : '#7d8bff',
              }"
              :disabled="scope.row.online_flag == 1 ? true : false"
              type="text"
              size="small"
              @click="handClick(scope.row, 'up')"
              >上线</el-button
            >
            <el-button
              :style="{
                color: scope.row.online_flag == 0 ? '#ccc' : '#7d8bff',
              }"
              :disabled="scope.row.online_flag == 0 ? true : false"
              style="color: #7d8bff"
              type="text"
              size="small"
              @click="handClick(scope.row, 'down')"
              >下线</el-button
            >
            <el-button
              style="color: #7d8bff"
              type="text"
              size="small"
              @click="handClick(scope.row, 'edit')"
              >编辑</el-button
            >
            <el-button style="color: #7d8bff" type="text" size="small"
              ><el-popconfirm
                @confirm="handClick(scope.row, 'del')"
                title="确定删除吗？"
              >
                <el-button
                  style="color: #7d8bff"
                  type="text"
                  size="small"
                  slot="reference"
                  >删除</el-button
                >
              </el-popconfirm></el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="change_page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filterForm.page"
        :page-sizes="[6, 12, 18, 24]"
        :page-size="filterForm.limit"
        layout="sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="新增公告"
      :visible.sync="showNewCreate"
      width="30%"
    >
      <div class="addform_main">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="公告名称" prop="name">
            <el-input placeholder="请输入" v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="培训内容" prop="introduction">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.introduction"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训讲师" prop="teacher_name">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.teacher_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训时间" prop="training_time">
            <el-date-picker
              prefix-icon="el-icon-date"
              v-model="ruleForm.training_time"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="培训链接" prop="link">
            <el-input placeholder="请输入" v-model="ruleForm.link"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetNews('ruleForm')">取 消</el-button>
        <el-button @click="addNews('ruleForm', 0)">确 定</el-button>
        <el-button
          style="background: #8186ea; border: none"
          type="primary"
          @click="addNews('ruleForm', 1)"
          >确定并上线</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="编辑公告"
      :visible.sync="showEditCreate"
      width="30%"
    >
      <div class="addform_main">
        <el-form
          :model="editRuleForm"
          :rules="rules"
          ref="editRuleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="公告名称" prop="name">
            <el-input
              placeholder="请输入"
              v-model="editRuleForm.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训内容" prop="introduction">
            <el-input
              placeholder="请输入"
              v-model="editRuleForm.introduction"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训讲师" prop="teacher_name">
            <el-input
              placeholder="请输入"
              v-model="editRuleForm.teacher_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训时间" prop="training_time">
            <el-date-picker
              prefix-icon="el-icon-date"
              v-model="editRuleForm.training_time"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="培训链接" prop="link">
            <el-input
              placeholder="请输入"
              v-model="editRuleForm.link"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetNewsEdit('editRuleForm')">取 消</el-button>
        <el-button @click="addNewsEdit('editRuleForm', 0)">确 定</el-button>
        <el-button
          style="background: #8186ea; border: none"
          type="primary"
          @click="addNewsEdit('editRuleForm', 1)"
          >确定并上线</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BjTime from '@/utils/time.js'

export default {
  data() {
    return {
      showEditCreate: false,
      flagType: '',
      total: 0,
      rules: {
        // 判断
        name: [{ required: true, message: '请输入公告名称', trigger: 'blur' }],
        introduction: [
          { required: true, message: '请输入培训内容', trigger: 'blur' },
        ],
        link: [{ required: true, message: '请输入培训链接', trigger: 'blur' }],
        teacher_name: [
          { required: true, message: '请输入培训讲师', trigger: 'blur' },
        ],
        training_time: [
          { required: true, message: '请选择培训时间', trigger: 'change' },
        ],
      },
      editRuleForm: {
        name: '',
        introduction: '',
        link: '',
        online_flag: '',
        teacher_name: '',
        training_time: '',
      },
      ruleForm: {
        // 添加培训公告表单
        name: '',
        introduction: '',
        link: '',
        online_flag: '',
        teacher_name: '',
        training_time: '',
      },
      showNewCreate: false, // 是否现在新增培训公告表单
      tableData: [],
      liveStatusOptions: [
        // 筛选下拉
        { label: '全部', value: -1 },
        { label: '已上线', value: 1 },
        { label: '未上线', value: 0 },
      ],
      filterForm: {
        // 参数
        online_flag: -1,
        name: '',
        page: 1,
        limit: 6,
      },
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  methods: {
    // 获取公告列表
    getList() {
      this.$request.getTranningPortList(this.filterForm, (res) => {
        console.log('培训公告列v表', res)
        this.total = res.data.total
        this.tableData = res.data.data
      })
    },
    // 搜索筛选
    filterNameFun() {
      this.getList()
    },
    // 选择筛选
    selectFilter() {
      this.getList()
    },
    // 操作上线 下线 删除 编辑
    handClick(row, type) {
      switch (type) {
        case 'up':
          this.upAndDown('up', row.id)
          break
        case 'down':
          this.upAndDown('down', row.id)
          break
        case 'del':
          this.del(row.id)
          break
        case 'edit':
          this.editRuleForm = row
          this.showEditCreate = true
          break
      }
    },
    // 上线 下线
    upAndDown(type, id) {
      this.$request.doTranningPortList(
        { announce_id: id, online_flag: type == 'up' ? 1 : 0 },
        (res) => {
          if (res.status == 1) {
            this.$message.success(type == 'up' ? '上线成功' : '下线成功')
            this.getList()
          }
        }
      )
    },
    // 删除
    del(id) {
      this.$request.delTranningPortList({ announce_id: id }, (res) => {
        console.log('删除', res)
        if (res.status == 1) {
          this.$message.success('删除成功')
          this.getList()
        }
      })
    },
    // 打开链接
    openLink(link) {
      window.open(link, '_blank')
    },
    // 新建公告 -- 打开弹窗
    newAnnouncement() {
      this.flagType = 'add'
      this.showNewCreate = true
    },
    // 新建公告 -- btn
    resetNews(formName) {
      this.showNewCreate = false
      this.ruleForm = {}
      this.$refs[formName].resetFields()
    },
    addNews(formName, num) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let payload = {}
          this.ruleForm.online_flag = num
          this.ruleForm.training_time = BjTime(this.ruleForm.training_time)
          payload = {
            ...this.ruleForm,
          }
          this.$request.addTranningPortList(payload, (res) => {
            console.log('add-- res', res)
            if (res.status == 1) {
              this.$message.success('操作成功')
              this.showNewCreate = false
              this.getList()
              this.$refs[formName].resetFields()
              this.ruleForm = {
                name: '',
                introduction: '',
                link: '',
                online_flag: '',
                teacher_name: '',
                training_time: '',
              }
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 编辑取消
    resetNewsEdit() {
      this.showEditCreate = false
    },
    // 编辑确定 确定上线
    addNewsEdit(formName, num) {
      if (this.editRuleForm.online_flag == 1) {
        num = 1
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.editRuleForm.online_flag = num
          this.editRuleForm.training_time = BjTime(
            this.editRuleForm.training_time
          )
          let payload = {
            announce_id: this.editRuleForm.id,
            name: this.editRuleForm.name,
            introduction: this.editRuleForm.introduction,
            link: this.editRuleForm.link,
            teacher_name: this.editRuleForm.teacher_name,
            online_flag: this.editRuleForm.online_flag,
            training_time: this.editRuleForm.training_time,
          }
          this.$request.addTranningPortList(payload, (res) => {
            console.log('add-- res', res)
            if (res.status == 1) {
              this.$message.success('操作成功')
              this.showEditCreate = false
              this.getList()
              this.$refs[formName].resetFields()
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 每页显示条数
    handleSizeChange(limit) {
      this.filterForm.limit = limit
      this.getList()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filterForm.page = page
      this.getList()
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.LiveAnnouncement {
  background: white;
  padding: 10px 30px;
  .head_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left_menu {
      width: 50%;
      display: flex;
      align-items: center;
      .input {
        width: 40%;
        margin-right: 40px;
      }
      .select {
        display: flex;
        width: 40%;
        align-items: center;
        justify-content: flex-start;
        .select_title {
          display: flex;
          align-items: center;
        }
      }
    }
    .right_menu {
      .new_btn {
        padding: 7px 13px;
        color: white;
        background: #8186ea;
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }
  .table {
    margin-top: 20px;
  }
  .change_page {
    text-align: right;
    padding: 50px 30px 10px 0;
  }
  .addform_main {
    /deep/ .el-select {
      width: 100%;
    }
    /deep/ .el-date-editor.el-input {
      width: 100%;
    }
  }
}
</style>
